import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import ThankYouPage from "./pages/ThankYouPage";
import "./App.css";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/thank_you" element={<ThankYouPage />} />
    </Routes>
  );
}

export default App;
