import React from 'react';

function ThankYouPage() {
  return (
    <div style={{
      textAlign: 'center',
      padding: '50px',
      backgroundColor: '#f9f9f9',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
    }}>
      <h1 style={{
        color: 'black',
        fontSize: '2.5em',
        margin: '0 0 20px 0'
      }}>
        Спасибо!
      </h1>
      <p style={{
        color: 'black',
        fontSize: '1.2em',
        margin: '0',
        lineHeight: '1.6'
      }}>
        Мы свяжемся с вами в ближайшее время. Ожидайте нашего ответа!
      </p>
    </div>

  );
}

export default ThankYouPage;
